.image-container {
    position: relative;
    width: 100%;
    /* border-width: 2px 2px 2px 0;
    border-style: solid;
    border-color: transparent; */
    background-image: url('../image/NewCompressed/remaining/Round Rectangle 12.gif');
    animation: popIn 1s ease forwards, playGif 5s infinite;

  }

  
  .pop-image {
    position: absolute;
    right: 0;
    transform: translateY(-50%);
    opacity: 0;
    
  }
  
  @keyframes popIn {
    from {
      /* opacity: 0; */
      transform: translateX(100%);
    }
    to {
      /* opacity: 1; */
      transform: translateX(0);
    }
  }

  @keyframes playGif {
    0% {
      background-image: url('../image/NewCompressed/remaining/Round Rectangle 12.gif');
    }
    100% {
      background-image: url('../image/NewCompressed/remaining/Round Rectangle 12.gif');
    }
  }
  
  /* @keyframes borderCycle {
    0% {
      border-color: #ff0000; 
    }
    25% {
      border-color: #00ff00; 
    }
    50% {
      border-color: #0000ff; 
    }
    75% {
      border-color: #ffff00;
    }
    100% {
      border-color: #ff00ff;
    }
  } */

  