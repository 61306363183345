@media (min-height:300px) {
    .left-right-height{
      height: 240px !important;
    }
    .user-bound1{
        padding: 2px !important
    }
}

@media (min-height:320px) {
    .left-right-height{
      height: 250px !important
    }
    .user-bound1{
        padding: 4.5px !important
    }
}

@media (min-height:340px) {
    .left-right-height{
      height: 270px !important
    }
    .user-bound1{
        padding: 7px !important
    }
}

@media (min-height:370px) {
    .left-right-height{
      height: 300px !important
    }
    .user-bound1{
        padding: 10px !important
    }
}

@media (min-height:350px) {
  .editName1{
    padding-top: 37px !important;
    height: 290px !important;
  }
  .editUser-form{
    padding:10px !important;
  }
}


