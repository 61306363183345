.binding-p1 {
    font-size: 22px;
    text-align: center;
    color: white;
    font-weight: bolder;
    margin: 10px;
  }
  
  #phone,
  #OTP,
  #password {
    background-color: black;
    color: red;
    font-size: 14px;
    border-radius: 15px;
    margin-left: 15px;
    margin: 10px;
    min-width: 225px;
    box-sizing: border-box;
    text-align: center;
  }
  
  .binding-label {
    width: 124px;
    text-align: right;
    display: inline-block;
    color: burlywood;
  }
  
  .valid {
    display: block;
    padding: 7px 10px;
    border-radius: 15px;
    font-size: 17px;
    color: white;
    background-color: rgb(32, 118, 32);
    margin: 10px auto;
  }
  
  .invalid {
    display: block;
    padding: 7px 10px;
    border-radius: 15px;
    font-size: 17px;
    color: white;
    background-color: rgb(85, 73, 74);
    margin: 10px auto;
  }
  
  .validotp {
    padding: 7px 10px;
    border-radius: 15px;
    font-size: 17px;
    color: white;
    background-color: rgb(32, 118, 32);
  }
  .invalidotp {
    padding: 7px 10px;
    border-radius: 15px;
    font-size: 17px;
    color: white;
    background-color: rgb(85, 73, 74);
  }
  
  @media (max-width: 600px) {
    .binding-label {
      width: 150px;
    }
    #phone,
    #OTP,
    #password {
      min-width: 200px;
    }
  }
  
  @media (max-height: 300px) {
    #phone,
    #OTP,
    #password {
      margin: 8px 10px;
    }
    .binding-p1 {
      margin: 8px;
      font-size: 21px;
    }
    .binding-button {
      padding: 5px 10px;
    }
  }