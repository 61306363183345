/* .section {
  font-size: 11px;
} */
/* .section {
  background-color: aliceblue;
} */
.activee {
  background-image: url("../../image/NewCompressed/remaining//button.png");
  background-size: 80% 100%;
  background-position: center;
  background-repeat: no-repeat;
  color:white !important ;
}

.activee1 {
  background-image: url("../../image/NewCompressed/remaining//button.png");
  background-size: contain;
  padding: 5px;
  background-position: center;
  background-repeat: no-repeat;
  color:white !important;
}
