/* form{
    background-color: #5b2222 !important;
} */

.display-block{
    display: block;
}

.history2::-webkit-scrollbar {
    display: none;
  }
  
  .history2{
    scrollbar-width: none;
  }

@media (min-height:330px) {
.img1{
    height: unset !important ;
    background-size: 85% 100% !important;
    margin-bottom: 10px !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    margin-top: 10px !important
}
/* p{
    font-size: 12px !important;
} */
.button1{
    margin-top: 12px !important;
}
.bank1{
    height: unset;
    padding: 10px 0;
}
.history2{
    height: 190px !important
}
.withdrow-main-container{
    height:240px !important;
   }
}
@media (min-height:350px) {
    .UPI1{
        display: flex !important;
        align-items: center !important;
        position: unset !important;
        transform: unset !important;
        height: 280px !important;
    }

    .button2{
        padding: 16px 0;
    }

    .history2{
        height: 210px !important
    }

    .withdrow-main-container{
        height:270px !important;
       }
    
}
@media (min-height:360px) {
    /* p{
        font-size: 14px !important;
    } */

   .withdrow-main-container{
    height:290px !important;
   }

    .type1{
        padding: 5px;
    }

    .button1{
        padding: 16px !important;
    }

    .bank1{
        padding: 15px 0;
        margin: 15px auto;
    }

    .history2{
        height: 240px !important
    }

    /* .img1{
        margin-top: 10px;
        margin-bottom: 15px;

    }
    .img1{
        margin-top: 10px;
        margin-bottom: 15px;
        
    } */
}