.profile-avtar-header{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.profile-avtar-header p{
    font-size: 22px;
    color: burlywood;
}

.profile-avtar-main{
    display: block;
    width: 100px;
}

.profile-avtar-bg1{
    padding: 10px;
    background-color: rgb(52, 16, 16);
    margin: 10px;
    border-radius: 15px;
}

.profile-avtar-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 10px;
}

.profile-avtar-child{
    max-width:60px;
}

@media (max-height:360px) {
    .profile-avtar-main{
        display: block;
        width: 80px;
    }
}

@media (max-height:330px) {
    .profile-avtar-main{
        display: block;
        width: 70px;
    }
    .profile-avtar-bg1{
        padding: 6px;
        margin: 6px;
    }

    .profile-avtar-container{
        margin: 6px;
    }
}

@media (max-height:300px) {
    .profile-avtar-main{
        display: block;
        width: 65px;
    }

    .profile-avtar-header{
       margin: -9px;
    }
    
}